.event-diary {
  &__header {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__date {
    color: $color-gray;
    margin-left: 6px;
  }

  &__subtitle {
    display: flex;
    column-gap: 4px;
  }

  &__item {
    padding: 16px;
    margin-bottom: 8px;
    background: $color-layout;
    border-radius: 2px;

    &_question {
      margin-bottom: 8px;
      font-family: $fontFamily-Inter-Regular;
      line-height: 150%;
    }

    & pre {
      margin: 0;
      font-family: inherit;
      font-size: 16px;
      white-space: pre-line;
    }
  }
}