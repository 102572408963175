.patients-filter {
  &__btn {
    min-width: 153px;
    height: 48px;
    justify-content: center;
    column-gap: 8px;

    & .anticon-down {
      margin: 0 !important;

      & svg {
        width: 10px;
        height: 10px;
      }
    }
  }

  &__wrapper {
    width: 529px;
    padding: 24px 32px 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: $color-white;
    border: 1px solid $color-gray-light;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 2px 0px 2px 2px;
  }

  &__entry {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__input {
    width: 100%;
    max-width: 160px;

    & input {
      height: 48px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    column-gap: 16px;
  }
}