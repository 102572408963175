.care-plan {
  margin-bottom: 16px;
  font-family: $fontFamily-Inter-Regular;
  font-size: $fontSize-xxs;
  color: $color-gray;

  &__title {
    color: $color-black;
  }

  &__btn-edit {
    height: auto;
    margin-top: 6px;
    column-gap: 0;
    font-family: $fontFamily-Inter-Regular;

    & svg {
      width: 16px;
      height: 16px;
      margin-right: 3px;

      & path {
        fill: $color-primary;
      }
    }
  }

  &__container {
    position: relative;
  }

  &__description {
    &-collapsible {
      height: 112px;
      overflow: hidden;
    }

    &-visible {
      height: max-content;
      overflow: visible;
    }

    & strong {
      font-family: $fontFamily-Inter-Bold;
    }

    & a {
      text-decoration: underline;
    }

    & blockquote {
      border-left: 4px solid #ccc;
      margin-bottom: 5px;
      margin-top: 5px;
      padding-left: 16px;
    }

    & h1 {
      display: block;
      font-size: 2em;
      margin-top: 0.67em;
      margin-bottom: 0.67em;
      font-weight: bold !important;
      color: $color-gray;
    }

    & h2 {
      display: block;
      font-size: 1.5em;
      margin-top: 0.83em;
      margin-bottom: 0.83em;
      font-weight: bold !important;
      color: $color-gray;
    }

    & .ql-align-center {
      text-align: center;
    }

    & .ql-align-right {
      text-align: right;
    }

    & .ql-align-justify {
      text-align: justify;
    }
  }

  &__btn-toggle {
    position: absolute;
    right: 0;
    bottom: 0;
    height: auto;
    padding-left: 10px;
    margin-bottom: 1px;
    background: $color-white;
    font-family: "Inter-Regular", sans-serif;
    box-shadow: -5px 0px 5px 5px rgba(255, 255, 255, 0.9);
    transition: none;

    & span {
      text-decoration: none;
    }

    &:hover,
    &:focus,
    &:active {
      background: $color-white;

      & span {
        text-decoration: none;
      }
    }
  }
}
