.timer {
  font-family: $fontFamily-Inter-Regular;
  font-size: $fontSize-xxs;
  line-height: 140%;

  &__title {
    margin-bottom: 15px;
  }

  &__count {
    margin: 0 8px;
  }

  &__btns-wrapper {
    margin-bottom: 14px;
    display: flex;
    column-gap: 16px;
  }

  &__spent-time {
    margin-bottom: 10px;
  }

  &__week {
    margin-bottom: 0;
    color: $color-gray;
  }

  &__editor {
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    font-family: $fontFamily-Inter-Medium;
    font-size: $fontSize-xlg;
    line-height: 140%;
  }

  &__footer {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
}