.header {
  height: 80px;
  padding-right: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $color-primary;
  font-family: $fontFamily-Inter-Medium;
  font-size: $fontSize-xs;
  line-height: 140%;
  color: $color-white;

  &__icon {
    width: 266px;
    height: 80px;
    background-image: url(../../images/svg/celeri-icon-2.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  &__nav {
    flex: 1;
    padding-left: 58px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .ant-menu-horizontal {
      line-height: 22px;
    }

    & .ant-menu-item,
    & .ant-menu-submenu {
      margin: 0 22px !important;
      font-family: $fontFamily-Inter-Medium;
      font-size: $fontSize-xs;
      color: $color-white;

      & a{
        color: $color-white;
      }

      &-selected,
      &:hover {
        background: $color-primary-dark;

        & a{
          color: $color-white;
        }
      }
    }
  }

  &__login-icon {
    width: 79px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-primary-dark;
    border-radius: 2px;
  }

  &__logout-icon {
    width: 20px;
    height: 20px;
    background-image: url(../../images/svg/logout.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  &__menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__submenu {
    & .ant-menu {
      margin-left: -17px;
    }

    & .ant-menu-item {
      &-selected,
      &:hover {
        background: $color-layout;

        & a{
          color: $color-black;
        }
      }
    }
  }
}
