.billing-details-modal {
  &__description {
    margin-top: 14px;
    display: flex;
    column-gap: 16px;
  }
  
  &__link {
    & span {
      color: $color-black;
      text-decoration: underline;
    }

    &:focus span,
    &:hover span {
      color: $color-black !important;
      text-decoration: underline;
    }
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td:not(:last-child) {
    padding-right: 30px !important;
    white-space: nowrap;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}