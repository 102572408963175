.diary-dots {
  &__dot {
    width: 8px;
    height: 8px;
    margin: 0 4px;
    display: inline-block;
    border-radius: 50%;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}