@import '../abstracts/variables';

.ant-layout {
  background: none;
}

.ant-input {
  padding: 12px 16px;
  font-family: $fontFamily-Inter-Regular;
  font-size: 15px;
  line-height: 140%;
  border-color: $color-gray-light;

  &::placeholder {
    color: $color-gray;
  }

  &:hover {
    border-color: $color-primary;
  }

  &:focus {
    border-color: $color-primary;
    box-shadow: 0 0 0 2px rgba(19, 41, 78, 0.2);
  }

  &-affix-wrapper-status-success {
    border-color: $color-green;

    & svg {
      width: 20px;
      height: 20px;
      color: $color-green;
    }
  }

  &-affix-wrapper-status-error {
    border-color: $color-red;

    & svg {
      width: 20px;
      height: 20px;
      color: $color-red;
    }
  }

  &-affix-wrapper {
    &:hover {
      border-color: $color-primary !important;
    }

    &-focused {
      border-color: $color-primary !important;
      box-shadow: 0 0 0 2px rgba(19, 41, 78, 0.2);
    }
  }

  &-number {
    &:hover {
      border-color: $color-primary !important;
    }

    &-focused {
      border-color: $color-primary !important;
      box-shadow: 0 0 0 2px rgba(19, 41, 78, 0.2);
    }

    &-affix-wrapper {
      &:hover {
        border-color: $color-primary !important;
      }

      &-focused {
        border-color: $color-primary !important;
        box-shadow: 0 0 0 2px rgba(19, 41, 78, 0.2);
      }
    }
  }
}

.ant-menu {
  width: 100%;
  background: transparent;
  border-bottom: none;

  &-horizontal:not(.ant-menu-dark) > &-item,
  &-horizontal:not(.ant-menu-dark) > &-submenu {
    padding: 8px 18px;
    margin: 0;
    border-radius: 2px;
  }

  &-horizontal > &-item,
  &-horizontal > &-submenu {
    top: 0;
  }

  &-title-content {
    color: $color-white;
  }

  &-item-selected::after,
  &-item-active::after,
  &-submenu-selected::after,
  &-submenu-active::after {
    display: none;
  }

  &-submenu-popup {
    width: auto;
  }
}

.ant-space {
  color: $color-white;

  &-item {
    width: max-content;
  }
}

.ant-input-affix-wrapper {
  padding: 0;
  padding-right: 12px;

  & input.ant-input {
    padding: 12px 16px;
  }
}

.ant-picker {
  width: 100%;
  padding: 10px 10px 11px;

  &:hover {
    border-color: $color-primary;
  }

  &-focused {
    border-color: $color-primary;
    box-shadow: 0 0 0 2px rgba(19, 41, 78, 0.2);
  }

  &-range-separator {
    display: none;
  }

  &-active-bar {
    background: $color-primary !important;
  }

  &-cell-selected {
    & .ant-picker-cell-inner {
      background: $color-primary !important;
    }
  }

  &-cell-range-start {
    & .ant-picker-cell-inner {
      background: $color-primary !important;
    }
  }

  &-cell-range-end {
    & .ant-picker-cell-inner {
      background: $color-primary !important;
    }
  }

  &-cell-today {
    & .ant-picker-cell-inner {
      &::before {
        border-color: $color-primary !important;
      }
    }
  }

  &-today-btn {
    color: $color-primary;

    &:hover {
      color: $color-primary;
    }
  }

  &-preset {
    & .ant-tag {
      border-color: $color-primary !important;
      color: $color-primary !important;
      background: rgba(19, 41, 78, 0.2) !important;
    }
  }

  &-now-btn {
    color: $color-primary;
  }

  &-ok {
    & .ant-btn {
      background: $color-primary;

      &:hover {
        color: $color-white;
      }
    }
  }
}

.ant-picker-input input {
  font-family: $fontFamily-Inter-Regular;
  font-size: $fontSize-xs;
  line-height: 140%;

  &::placeholder {
    color: $color-gray;
  }
}

.ant-radio {
  &-inner {
    width: 24px;
    height: 24px;
    border-color: $color-green;

    &::after {
      width: 18px;
      height: 18px;
      margin-top: -9px;
      margin-left: -9px;
      background-color: $color-green;
    }
  }

  &:hover,
  &-checked {
    .ant-radio-inner {
      border-color: $color-green;
    }
  }

  &-input:focus + &-inner {
    border-color: $color-green;
    box-shadow: none;
  }

  &-wrapper {
    align-items: start;

    & span:nth-child(2):not(.ant-radio-inner) {
      display: inline-block;
      padding: 4px 4px 0;
      margin: 0 12px;
    }
  }
}

.ant-checkbox {
  &-inner {
    width: 24px;
    height: 24px;

    &::after {
      left: 35%;
    }
  }

  &-checked &-inner {
    background-color: $color-white;
    border-color: $color-green;

    &::after {
      border: 2px solid $color-green;
      border-top: 0;
      border-left: 0;
    }
  }

  &:hover {
    .ant-checkbox-inner {
      border-color: $color-green;
    }
  }

  &-input:focus + &-inner,
  &-checked::after {
    border-color: $color-green;
  }

  &-wrapper {
    align-items: start;

    & span:nth-child(2):not(.ant-checkbox-inner) {
      display: inline-block;
      padding: 4px 4px 0;
      margin: 0 12px;
    }
  }
}

.ant-btn {
  height: 42px;
  padding: 4px 24px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-family: $fontFamily-Inter-Medium;
  line-height: 140%;
  border-color: $color-gray-light;

  & svg {
    width: 20px;
    height: 20px;
  }

  & span {
    margin-left: 0;
  }

  &:hover {
    color: $color-primary;
    border-color: $color-primary;
  }

  &:focus {
    color: $color-primary;
    border-color: $color-primary;
  }
}

.ant-space-item a {
  text-decoration: underline;
}

.ant-table {
  & * {
    font-family: $fontFamily-Inter-Regular;
    font-size: $fontSize-xxs;
    line-height: 150%;
  }

  &-wrapper {
    margin-bottom: 24px;
  }

  & th {
    background: transparent !important;

    &::before {
      display: none;
    }
  }

  &-tbody {
    & tr:nth-child(odd) {
      background: $color-blue-light;
    }

    & tr:nth-child(even) {
      background: transparent;
    }

    & > tr > td {
      padding: 15px 8px !important;
      border: none;
    }

    & > tr:nth-child(odd):hover > td {
      background: $color-blue-light;
    }

    & > tr:nth-child(even):hover > td {
      background: transparent;
    }
  }

  &-thead > tr > th {
    border: none;
    color: $color-gray;
  }
}

.ant-modal {
  padding-bottom: 0;

  &-content {
    padding: 32px 32px 40px;
  }

  &-header {
    padding: 0;
    border-bottom: none;
  }

  &-title {
    font-family: $fontFamily-Inter-Medium;
    font-size: $fontSize-xlg;
    line-height: 140%;
  }

  &-body {
    padding: 0;
  }

  &-footer {
    padding: 0;
    border-top: none;
    display: flex;
    align-items: center;
  }
}

.ant-pagination {
  &-prev {
    &:hover {
      & .ant-pagination-item-link {
        border-color: $color-primary;
        color: $color-primary;
      }
    }
  }

  &-item {
    &-active {
      border-color: $color-primary;

      & a {
        color: $color-primary;
      }
    }

    &:hover {
      border-color: $color-primary;

      & a {
        color: $color-primary;
      }
    }
  }

  &-next {
    &:hover {
      & .ant-pagination-item-link {
        border-color: $color-primary;
        color: $color-primary;
      }
    }
  }
}

.ant-select {
  &:hover {
    & .ant-select-selector {
      border-color: $color-primary !important;
    }
  }

  &-focused {
    & .ant-select-selector {
      border-color: $color-primary !important;
      box-shadow: 0 0 0 2px rgba(19, 41, 78, 0.2) !important;
    }
  }
}

.ant-badge {
  &-count {
    background: $color-primary !important;
  }
}

.ant-tabs {
  &-tab {
    &-active {
      & .ant-badge {
        & .ant-scroll-number {
          background: $color-white !important;
        }
      }
    }
  }
}

.ant-spin {
  &-dot-item {
    background-color: $color-primary;
  }
}
