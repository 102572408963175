.editor {
  .ql-formats {
    margin-bottom: 0 !important;
  }

  .ql-editor {
    min-height: 180px;
    font-family: $fontFamily-Inter-Regular;
    font-size: $fontSize-xs;
  
    p {
      margin-bottom: 1em;
      color: $color-black;
    }

    strong {
      font-family: $fontFamily-Inter-Bold;
    }

    & h1 {
      font-weight: bold !important;
    }

    & h2 {
      font-weight: bold !important;
    }
  }
}