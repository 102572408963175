.promis {
  &__description {
    color: $color-gray;
  }

  & .ant-table-tbody > tr > td {
    padding: 16px !important;
  }

  & .ant-table-thead > tr > th:nth-child(2),
  & .ant-table-thead > tr > th:nth-child(3),
  & .ant-table-thead > tr > th:nth-child(4) {
    width: 120px;
  }
}
