.billing-body {
  &__table {
    & .ant-table-cell {
      cursor: pointer;
    }
  }

  &__unenrolled-item {
    color: $color-red;
  }

  &__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: $color-white;

    &_container {
      max-width: 1320px;
      padding: 18px 0;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &_btns-wrapper {
      display: flex;
      align-items: center;
      column-gap: 29px;
    }

    &_btn-reject {
      color: $color-red;

      &:hover,
      &:focus {
        border-color: $color-gray-light;
        color: $color-red;
      }
    }
  }
}