.timings-body {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  &__label {
    font-family: $fontFamily-Inter-Medium;
    font-size: $fontSize-md;
  }

  & table {
    border-spacing: 0 16px;
  }

  & thead th {
    padding: 0 16px;
  }

  & tbody tr {
    background: $color-layout !important;

    & td.ant-table-cell {
      padding: 10px !important;
    }
  }

  & .ant-input-number-input-wrap {
    width: 100%;
  }

  &__timepicker {
    height: 40px;
    padding: 10px 12px;
    border: 1px solid #d4d4d8;
    border-radius: 4px;

    & .ant-picker-input input, & .ant-picker-input span {
      font-family: $fontFamily-Inter-Regular;
      font-size: 15px;
      line-height: 150%;
      color: #71717a;
    }

    &-error {
      border: 1px solid $color-red;
      box-shadow: 0 0 0 2px rgba(255, 24, 24, 0.2);

      &:hover {
        border-color: $color-red;
      }
    }
  }

  & .ant-input.timings-body__input {
    height: 40px;
    padding: 10px 12px !important;
    font-family: $fontFamily-Inter-Regular;
    font-size: 15px;
    line-height: 150%;
    color: #71717a;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &__code {
    height: 40px;
    padding: 10px 12px !important;
    border: 1px solid #d4d4d8;
    border-radius: 4px;
    font-family: $fontFamily-Inter-Regular;
    font-size: 15px;
    line-height: 150%;
    color: #71717a;

    &-error {
      border: 1px solid $color-red;
      box-shadow: 0 0 0 2px rgba(255, 24, 24, 0.2);

      &:hover,
      &:focus {
        border: 1px solid $color-red;
        box-shadow: 0 0 0 2px rgba(255, 24, 24, 0.2);
      }
    }
  }

  &__fee {
    width: 100%;
    -webkit-padding-start: 0;
    padding-inline-start: 0;
    padding-inline-end: 12px;
    display: flex;
    flex-direction: row-reverse;
    border: 1px solid #d4d4d8;
    border-radius: 4px;
    font-family: $fontFamily-Inter-Regular;
    font-size: 15px;
    line-height: 150%;
    color: #71717a;

    & .ant-input-number-handler-wrap {
      display: none;
    }

    &.ant-input-number {
      padding-inline-end: 6px;
    }

    & .ant-input-number-input {
      height: 40px;
      padding: 10px 0 10px 12px !important;
    }

    &-error {
      border: 1px solid $color-red;
      box-shadow: 0 0 0 2px rgba(255, 24, 24, 0.2);

      &:hover,
      &:focus {
        border-color: $color-red !important;
        box-shadow: 0 0 0 2px rgba(255, 24, 24, 0.2);
      }

      &.ant-input-number {
        box-shadow: none;
      }
    }
  }

  & .ant-checkbox {
    &-inner {
      &::after {
        top: -3px;
        left: 0;
        width: 20px;
        height: 7px;
        transform: rotate(45deg) scale(0.3) translate(-50%, -50%);
        border-color: $color-green;
        border-width: 7px;
      }
    }
  
    &-checked .ant-checkbox-inner {
      background: $color-white;
      border-color: $color-green;
    }
  
    &:hover {
      .ant-checkbox-inner {
        border-color: $color-green;
      }
    }
  }

  &__add-btn {
    max-width: 172px;
    padding: 10px 12px;
    font-family: $fontFamily-Inter-Regular;
    font-size: $fontSize-xxs;
    line-height: 140%;
    border-radius: 2px;
    float: right;

    & svg {
      width: 16px;
      height: 16px;
    }
  }

  &__delete-btn {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
      background: transparent;
    }

    &::after {
      display: none !important;
      box-shadow: none;
    }
  }

  & .ant-form-item {
    margin: 0;
  }

  & .ant-form-item-explain {
    display: none;
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__monthly-items-table {
    & thead th:nth-child(2) {
      padding: 0;
    }

    & tbody tr  td.ant-table-cell:nth-child(2) {
      padding: 0 !important;
    }
  }
}