@import '../abstracts/variables';

@font-face {
  font-family: 'Inter-Regular';
  src: url('../../fonts/Inter-Regular.ttf');
  font-weight: 400;
  font-style: normal
}
@font-face {
  font-family: 'Inter-Medium';
  src: url('../../fonts/Inter-Medium.ttf');
  font-weight: 500;
  font-style: normal
}
@font-face {
  font-family: 'Inter-Bold';
  src: url('../../fonts/Inter-Bold.ttf');
  font-weight: 700;
  font-style: normal
}
@font-face {
  font-family: 'Roboto-Regular';
  src: url('../../fonts/Roboto-Regular.ttf');
  font-weight: 400;
  font-style: normal
}
@font-face {
  font-family: 'Roboto-Medium';
  src: url('../../fonts/Roboto-Medium.ttf');
  font-weight: 500;
  font-style: normal
}
@font-face {
  font-family: 'Roboto-Bold';
  src: url('../../fonts/Roboto-Bold.ttf');
  font-weight: 700;
  font-style: normal
}

body {
  margin: 0;
  background-color: $color-layout;
}
  
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}