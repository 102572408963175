.event-charts {
  padding-top: 16px;

  &__subtitle {
    color: $color-gray;
  }

  &__diary-submissions {
    margin-bottom: 24px;

    .dxc-val-elements text {
      fill: $color-blue !important;
    }

    .event-charts__label::before {
      background: $color-blue;
    }
  }

  &__health-metrics {
    margin-bottom: 26px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 16px;

    &_item {
      width: 360px;

      .dxc-val-elements text {
        fill: $color-primary !important;
      }
  
      .event-charts__label::before {
        background: $color-primary;
      }

      &.sleep,
      &.distance {
        .dxc-val-elements text {
          fill: $color-black !important;
        }
    
        .event-charts__label::before {
          background: $color-black;
        }
      }

      &.steps {
        .dxc-val-elements text {
          fill: $color-orange !important;
        }
    
        .event-charts__label::before {
          background: $color-orange;
        }
      }
    }
  }

  .event-charts__label {
    margin-top: 16px;
    font-family: $fontFamily-Inter-Regular;
    font-size: $fontSize-xxs;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      width: 8px;
      height: 8px;
      margin-right: 8px;
      display: block;
      border-radius: 50%;
    }
  }
}

.chart-component {
  height: 240px;

  &__chart {
    width: 100%;
    height: 100%;
  }

  &__argument-label {
    white-space: pre;
    fill: #767676;
    font-family: "Segoe UI", "Helvetica Neue", "Trebuchet MS", Verdana, sans-serif;
    font-weight: 400;
    font-size: $fontSize-xxxs;
    cursor: default;
  }
}