.timings-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__description {
    display: flex;
    gap: 16px;
    font-family: $fontFamily-Inter-Regular;
    font-size: $fontSize-xxs;
    line-height: 140%;
  }

  &__date {
    display: flex;
    gap: 4px;
    color: $color-gray;
  }

  &__link {
    height: auto;
    font-family: $fontFamily-Inter-Regular;
  }
}