.tabs {
  .ant-tabs-nav {
    margin: 0;

    &::before {
      display: none;
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-tab {
    margin: 0;
    padding: 17px 40px;
    background: $color-white;
    border: 1px solid $color-gray-light;
    border-radius: 2px;
    font-family: $fontFamily-Inter-Regular;
    font-size: $fontSize-xs;
    line-height: 140%;

    &:hover {
      & .ant-tabs-tab-btn {
        color: $color-black;
      }
    }

    &-active {
      background: $color-primary;
      border: none;

      &:hover {
        & .ant-tabs-tab-btn {
          color: $color-white;
        }
      }

      & .ant-tabs-tab-btn {
        color: $color-white;
      }
    }
  }

  &-item {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  &-item__badge .ant-badge-count {
    background: $color-primary;
  }

  .ant-tabs-tab-active .tabs-item__badge .ant-badge-count {
    background: $color-white;
    color: $color-primary;
  }
}