.navigation-subheader {
    padding-left: 38px;
    margin-bottom: 28px;
    display: flex;
    align-items: center;
    font-size: $fontSize-xxxs;
    color: $color-gray;
    letter-spacing: 0.5px;
      
    &__back {
      padding: 0;
      margin: 0;
      cursor: pointer;
      background: transparent;
      border: none;
  
      & p {
        margin: 0;
        display: flex;
        align-items: center;
        gap: 20px;
        text-decoration: underline;
  
        &::before {
          content: '';
          width: 12px;
          height: 12px;
          background-image: url(../../images/svg/arrow-back.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 50%;
        }
      }
  
      &:hover p {
        text-decoration: none;
      }
    }
  
    &__description {
      margin: 0;
      display: flex;
      align-items: center;
  
      &::before {
        content: '-';
        margin: 0 4px;
      }
    }
  }