.common-content-layout {
  &__container {
    max-width: 1320px;
    margin: 0 auto;
    padding: 32px 0;
  }

  &__header {
    margin-bottom: 31px;
  }

  &__subheader {
    margin-bottom: 25px;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    column-gap: 24px;
  }

  &__body {
    flex: 1;
    padding: 32px 32px 35px;
    background: $color-white;
    box-shadow: 0px 2px 4px rgba(20, 69, 141, 0.1);
    border-radius: 2px;
  }

  &__panel {
    width: 424px;
  }
}