.time-editor {
  height: 114px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &__input {
    width: 66px;
    height: 68px;
    padding: 0;
    text-align: center;
    font-family: $fontFamily-Inter-Medium;
    font-size: $fontSize-xlg;
    line-height: 140%;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &__btn {
    &_increase,
    &_decrease {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      cursor: pointer;
    }
    
    &_increase {
      border-bottom: 10px solid $color-gray-light;

      &:hover {
        border-bottom: 10px solid $color-primary;
      }
    }

    &_decrease {
      border-top: 10px solid $color-gray-light;

      &:hover {
        border-top: 10px solid $color-primary;
      }
    }
  }
}