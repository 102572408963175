.phone {
  padding: 14px 0 0;
  display: flex;
  align-items: center;
  font-family: $fontFamily-Inter-Medium;
  font-size: $fontSize-xs;
  line-height: 140%;
  column-gap: 10px;

  &__number {
    margin-bottom: 0;
  }
}