.assessment-history {
  & .ant-table-tbody > tr > td {
    padding: 16px !important;
  }

  & .ant-table-thead > tr > th:first-child {
    width: 133px;
  }

  & .ant-table-thead > tr > th:last-child {
    width: 87px;
  }
}