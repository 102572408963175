.event-body {
  font-family: $fontFamily-Inter-Medium;
  font-size: $fontSize-xs;
  line-height: 140%;
  color: $color-black;

  &__header {
    margin-bottom: 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_status {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 6px;
      color: $color-gray;
    }
  }
}