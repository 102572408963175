.actions-card {
  padding: 32px 32px 40px;
  background: $color-white;
  box-shadow: 0px 2px 4px rgba(20, 69, 141, 0.1);
  border-radius: 2px;
  margin-bottom: 24px;

  &__item {
    padding-bottom: 16px;
    border-bottom: 1px solid $color-gray-light;

    &:last-child {
      padding-bottom: 0;
      border: none;
    }
  }

  &__actions {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 14px;
  }

  &__comment-icon {
    width: 20px;
    height: 20px;
    background-image: url(../../images/svg/comment-icon.svg);
  }

  &__timer-icon {
    width: 20px;
    height: 20px;
    background-image: url(../../images/svg/timer.svg);
  }

  &__btns-wrapper {
    display: flex;
    align-items: center;
    column-gap: 16px;
  }
}