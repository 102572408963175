.title {
  margin: 0;
  font-family: $fontFamily-Inter-Medium;
  font-size: $fontSize-xlg;
  line-height: 140%;
}

.subtitle {
  margin-bottom: 14px;
  font-family: $fontFamily-Inter-Medium;
  font-size: $fontSize-md;
  line-height: 140%;
}

.icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.search-input-suffix {
  width: 24px;
  height: 24px;
  background-image: url(../../images/svg/search-icon.svg);
}

.datepicker-suffix {
  width: 24px;
  height: 24px;
  background-image: url(../../images/svg/datepicker-icon.svg);
}

.btn {
  &-primary {
    background: $color-primary;
    color: $color-white;
    border: none;

    &:hover,
    &:focus {
      background: $color-primary;
      color: $color-white;
    }
  }

  &-orange {
    background: $color-orange;
    color: $color-white;
    border: none;

    &:hover,
    &:focus {
      background: $color-orange;
      color: $color-white;
    }
  }

  &-green {
    background: $color-green;
    color: $color-white;
    border: none;

    &:hover,
    &:focus {
      background: $color-green;
      color: $color-white;
    }
  }

  &-red {
    background: $color-red;
    color: $color-white;
    border: none;

    &:hover,
    &:focus {
      background: $color-red;
      color: $color-white;
    }
  }

  &-link {
    padding: 0;
    background: transparent;
    border: none;
    box-shadow: none;

    & span {
      color: $color-primary;
      text-decoration: underline;
    }

    &:hover,
    &:focus {
      background: transparent;

      & span {
        color: $color-primary;
        text-decoration: underline;
      }
    }

    &::after {
      display: none;
    }
  }

  &-view-link {
    padding: 0;
    background: transparent;
    border: none;
    box-shadow: none;

    & span {
      color: $color-gray;
      text-decoration: underline;
    }

    &:hover,
    &:focus {
      background: transparent;

      & span {
        color: $color-gray;
        text-decoration: underline;
      }
    }

    &::after {
      display: none;
    }
  }

  &-red-link {
    padding: 0;
    background: transparent;
    border: none;
    box-shadow: none;

    & span {
      color: $color-red;
      text-decoration: underline;
    }

    &:hover,
    &:focus {
      background: transparent;

      & span {
        color: $color-red;
        text-decoration: underline;
      }
    }

    &::after {
      display: none;
    }
  }
}

.color {
  &__gray {
    color: $color-gray;
  }

  &__green {
    color: $color-green;
  }

  &__yellow {
    color: $color-yellow;
  }

  &__orange {
    color: $color-orange;
  }

  &__red {
    color: $color-red;
  }
}

.bg {
  &__gray {
    background: $color-gray;
  }

  &__green {
    background: $color-green;
  }

  &__yellow {
    background: $color-yellow;
  }

  &__orange {
    background: $color-orange;
  }

  &__red {
    background: $color-red;
  }
}