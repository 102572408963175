.assessment {
  margin-bottom: 16px;
  font-family: $fontFamily-Inter-Regular;
  font-size: $fontSize-xxs;
  color: $color-gray;

  &__title {
    color: $color-black;
  }

  &__question_title {
    margin: 0;
  }

  &__answer_input {
    width: 500px;
  }

  &__answer_radio {
    margin: 10px 0 10px 0;
  }
}
