.patient-body {
    font-family: $fontFamily-Inter-Medium;
    font-size: $fontSize-xs;
    line-height: 140%;
    color: $color-black;
  
    &__header {
      margin-bottom: 26px;

      &-unenrolled {
        display: flex;
        align-items: center;
        column-gap: 12px;
        color: $color-red;
      }
    }
  }