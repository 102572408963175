.modal {
  &__body {
    min-height: 130px;
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    & p,
    & span {
      margin: 0;
      font-family: $fontFamily-Inter-Regular;
      font-size: $fontSize-xs;
      line-height: 140%;
      color: $color-gray;
    }

    & span {
      &:last-child {
        margin-bottom: 10px;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    column-gap: 26px;
  }

  &__patient-history_body {
    display: block;

    &.modal__body span:last-child {
      margin-bottom: 0;
    }
  }

  &__select {
    .ant-select-selector {
      height: 48px !important;
    }

    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
      margin-bottom: 0 !important;
    }

    .ant-select-selection-item {
      height: 48px;
      display: flex;
      align-items: center;
      color: $color-black;
    }
  }
}