.patient-details {
  padding: 24px;
  margin-bottom: 22px;
  display: flex;
  flex-direction: column;
  background: $color-layout;
  border-radius: 2px;
  font-family: $fontFamily-Inter-Regular;
  font-size: $fontSize-xxs;

  &__common {
    margin-bottom: 24px;
    display: flex;
    align-items: flex-start;
  }

  &__info {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(172px, max-content));
    row-gap: 8px;
    flex: 1;

    @media (max-width: 980px) {
      grid-template-columns: repeat(auto-fill, minmax(130px, max-content));
    }
  }

  &__item {
    margin-right: 4px;
    color: $color-gray;
  }

  &__ccda {
    color: $color-primary;
    text-decoration: underline;
    white-space: nowrap;

    &:hover {
      color: $color-primary;
    }
  }

  &__choose-btn {
    width: 100%;
    font-family: $fontFamily-Inter-Regular;
    font-size: $fontSize-xxs;
    line-height: 140%;
    display: flex;
    justify-content: center;

    &--error {
      border-color: $color-red;
      color: $color-red;
    }
  }

  &__diagnosis {
    display: flex;
    align-items: center;
    column-gap: 23px;
  }

  &__update-btn {
    height: auto;
    font-family: $fontFamily-Inter-Regular;
  }

  &__diagnosis-modal {
    & .modal__body .ant-radio {
      &-group {
        display: flex;
        align-items: center;
      }

      &-wrapper {
        display: flex;
        align-items: center;
      }

      &-inner {
        margin-bottom: 0;
      }
    }

    &_select {
      height: 43px;

      & .ant-select-selector {
        height: 43px !important;
      }

      & .ant-select-selection-placeholder {
        margin-bottom: 0 !important;
        display: flex;
        align-items: center;
      }

      & .ant-select-selection-search-input {
        height: 43px !important;
      }

      & .ant-select-selection-item {
        margin-bottom: 0 !important;
        display: flex;
        align-items: center;
      }
    }
  }

  &__history {
    &_container {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
    }

    &_date {
      display: flex;
      gap: 4px;
      color: $color-gray;
    }
  
    &_link {
      height: auto;
      font-family: $fontFamily-Inter-Regular;
    }
  }

  &__diagnosis-consent {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__diagnosis-modal_wrapper {
    display: flex;
    align-items: center;
    column-gap: 16px;

    & .patient-details__diagnosis-modal_select {
      flex: 1;
      width: 0;
    }
  }

  &__diagnosis-modal_add-btn {
    height: 43px;
    display: flex;
    align-items: center;
    column-gap: 8px;

    & span {
      margin: 0 !important;
    }
  }

  &__diagnosis-modal_diagnoses-list {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }

  &__diagnosis-modal_diagnoses-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__diagnosis-modal_remove-btn {
    width: 18px;
    height: 18px;
    padding: 0;
    border: none;
    box-shadow: none;
    transform: rotate(45deg);

    &::after {
      display: none !important;
      box-shadow: none;
    }

    & span {
      margin: 0 !important;
    }

    & svg {
      width: 18px;
      height: 18px;
      color: $color-red;
    }
  }

  &__edit-btn {
    column-gap: 0;

    & svg {
      width: 16px;
      height: 16px;
      margin-right: 3px;
    }
  }

  &__verbal-consent-modal {
    & .ant-picker {
      height: 48px;
    }

    & .ant-picker-suffix {
      margin-bottom: 0 !important;
    }

    & .anticon {
      margin-bottom: 0 !important;
    }
  }

  &__consent {
    &-wrapper {
      display: flex;
      align-items: center;
      column-gap: 16px;
    }

    &-data {
      display: flex;
      align-items: center;
      column-gap: 6px;
      color: $color-gray;
    }
  }
}